import mainLayout from "@/layout/mainLayout.vue"

const meetingsRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),
    children: [
      {
        path: "meetings",
        name: "meetings",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/meetings/list.vue")
      },
      {
        path: "meetings/add",
        name: "addMeeting",
        component: () => import("@/views/space/meetings/add.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      {
        path: "meetings/edit_basic_data/:meeting_id",
        name: "editMeeting",
        component: () => import("@/views/space/meetings/editBasicData.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      }
    ]
  }
]

export default meetingsRoutes
