<template>
  <Menu as="div" class="relative" v-slot="{ close }">
    <div class="flex items-center">
      <MenuButton @click.stop>
        <slot name="button"></slot>
      </MenuButton>
    </div>

    <MenuItems
      ref="menuRef"
      class="absolute mt-2 divide-y divide-gray-100 rounded-[12px] bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-30 !px-2"
      :class="props.position ? props.position : 'end-0'"
      @beforeEnter="checkPosition"
    >
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <slot name="content" :close="close"></slot>
      </transition>
    </MenuItems>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
const props = defineProps({
  position: {
    type: Boolean,
    required: false
  }
})
</script>
